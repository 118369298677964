import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["wrapper", "item"]

  connect () {
    // this.visibleElementCount = this.itemTargets.filter(item => this.#isOverflowing(this.wrapperTarget, item)).length
    // this.itemIndex = this.itemTargets.length - this.visibleElementCount
  }

  translate (event) {
    const direction = parseInt(event.params.direction)
    const el = this.itemTargets.at(direction > 0 ? -1 : 0)
    el.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" })
  }

  #isOverflowing (parent, child) {
    return child.getBoundingClientRect().right > parent.getBoundingClientRect().right
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "output", "input", "oldInput", "outputPlaceholder"];

  readURL () {
    const input = this.inputTarget;
    const output = this.outputTarget;

    if (input.files && input.files[0]) {
      const reader = new FileReader();

      const uploadFunction = reader.onload = async function () {
        if ( reader.result ) { output.style.backgroundImage = `url('${reader.result}')` }
        return output.src = reader.result;
      }
      uploadFunction().then(() => {
        if (this.hasOldInputTarget) {
          this.oldInputTarget.classList.add('hidden'); 
        }
        this.outputPlaceholderTarget.classList.add('hidden'); 
        this.outputTarget.classList.remove('hidden'); 
      })

      reader.readAsDataURL(input.files[0]);
    }
  }
}

import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  static values = {
    maxItems: Number,
    customValues: Array,
    searchField: String,
    labelField: String,
    valueField: String,
    endpointUrl: String,
    placeholder: String
  }

  connect() {
    this.initTomSelect()
  }

  disconnect() {
    if (this.select) this.select.destroy()
  }

  initTomSelect() {
    const that = this
    const options = {
      plugins: ["remove_button"],
      maxItems: this.hasMaxItemsValue ? this.maxItemsValue : null,
      valueField: this.hasValueFieldValue ? this.valueFieldValue : "id",
      labelField: this.hasLabelFieldValue ? this.labelFieldValue : "title",
      searchField: this.hasSearchFieldValue ? this.searchFieldValue : "title",
      placeholder: this.hasPlaceholderValue ? this.placeholderValue : "Select...",
      preload: true,
      openOnFocus: true
    }

    if (this.hasCustomValuesValue) {
      options["options"] = this.customValuesValue
      options["render"] = {
        option: function (data, escape) {
          return `
            <div>
              <h3 class="pt-1 font-semibold">${escape(data[options["labelField"]])}</h3>
              <p class="pb-1 text-gray-500 font-normal text-xs">${escape(data.description || "")}</p>
            </div>
            `
        },
        item: function (data, escape) {
          return `<div>${escape(data.title)}</div>`
        }
      }
    }

    if (this.hasEndpointUrlValue) {
      options["load"] = function (query, callback) {
        var url = `${that.endpointUrlValue}&query=${encodeURIComponent(query)}`
        fetch(url)
          .then(response => response.json())
          .then(json => {
            callback(json)
          })
          .catch(() => {
            callback()
          })
      }
    }

    this.select = new TomSelect(this.element, options)
  }
}

import { Controller } from "@hotwired/stimulus"
import GLightbox from "glightbox"

export default class extends Controller {
  static targets = ["lightboxable"]

  connect () {
    // this.element.classList.add("glightbox")
    this.lightbox = GLightbox({
      selector: "[data-lightbox-target='lightboxable']",
      touchNavigation: true,
      loop: true,
      autoplayVideos: true
    })
  }

  // You can set default options in this getter.
  get defaultOptions () {
    // return {
    //   download: false,
    //   mode: 'lg-fade',
    //   speed: 400
    // }
  }
}

import { Controller } from "@hotwired/stimulus"
import { Autocomplete } from "stimulus-autocomplete"

export default class TagAutocomplete extends Autocomplete {
  static targets = ["input", "hidden", "results", "wrapper", "tag", "spinner"]
  static classes = ["selected", "tag"]
  static values = {
    maxTags: Number,
    ready: Boolean,
    submitOnEnter: Boolean,
    url: String,
    minLength: Number,
    delay: { type: Number, default: 300 },
    queryParam: { type: String, default: "q" }
  }

  showSpinner() {
    this.spinnerTarget.classList.remove("hidden")
  }
  hideSpinner() {
    this.spinnerTarget.classList.add("hidden")
  }

  tags() {
    return this.wrapperTarget.querySelectorAll("[data-tag-autocomplete-target='tag']")
  }

  initialize() {
    super.initialize()
    this.tags().forEach(tagElement => {
      tagElement.classList.add(...this.tagClasses)
      tagElement.onclick = () => {
        tagElement.remove()
        this.updateHiddenTarget()
      }
    })
  }

  tagIDs() {
    const ids = []

    this.tags().forEach(tag => ids.push(tag.dataset.id))
    // return ids
    return ids.join(" ")
  }

  buildURL(query) {
    const url = new URL(this.urlValue, window.location.href)
    const params = new URLSearchParams(url.search.slice(1))

    params.append("q", query)
    params.append("exclude", this.tagIDs())
    url.search = params.toString()

    return url.toString()
  }

  updateHiddenTarget() {
    this.hiddenTarget.value = this.tagIDs()
    this.hiddenTarget.dispatchEvent(new Event("input"))
    this.hiddenTarget.dispatchEvent(new Event("change"))
  }

  commit(selected) {
    if (selected.getAttribute("aria-disabled") === "true") return

    if (selected instanceof HTMLAnchorElement) {
      selected.click()
      this.close()
      return
    }

    const textValue = selected.getAttribute("data-autocomplete-label") || selected.textContent.trim()
    const value = selected.getAttribute("data-autocomplete-value") || textValue
    this.inputTarget.value = "" // clear the input

    const tagElement = document.createElement("div")
    tagElement.classList.add(...this.tagClasses)
    tagElement.innerHTML = textValue
    tagElement.dataset.id = value
    tagElement.dataset.tagAutocompleteTarget = "tag"
    tagElement.onclick = () => tagElement.remove()
    this.wrapperTarget.appendChild(tagElement)

    if (this.hasHiddenTarget) {
      this.updateHiddenTarget()
    } else {
      this.inputTarget.value = value
    }

    // console.log(`tags: ${this.tags().length}/${this.maxTagsValue}`)
    // if (this.tags().length >= this.maxTagsValue) {
    //   this.inputTarget.disabled = true
    //   selected.setAttribute("aria-disabled", "true")
    // }

    this.inputTarget.focus()
    this.hideAndRemoveOptions()

    this.element.dispatchEvent(
      new CustomEvent("autocomplete.change", {
        bubbles: true,
        detail: { value: value, textValue: textValue, selected: selected }
      })
    )
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox"]
  static values = {
    limit: Number
  }

  connect() {
    this.limit()
  }

  limit () {
    let checkedCount = 0
    this.checkboxTargets.forEach(checkbox => {
      if (checkbox.checked) checkedCount++
    })

    this.checkboxTargets.forEach(checkbox => {
      checkbox.disabled = checkedCount === this.limitValue && !checkbox.checked
    })
  }
}

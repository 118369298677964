import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["revealable"]
  static values = {
    hideToggleButton: {
      type: Boolean,
      default: false
    }
  }

  reveal (event) {
    this.revealableTargets.forEach(revealable => revealable.classList.toggle("hidden"))

    if (this.hideToggleButtonValue) {
      event.target.classList.toggle("hidden")
    } else {
      event.target.innerText =
        event.target.innerText === event.params.revealedButtonText ? event.params.hiddenButtonText : event.params.revealedButtonText
    }
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["billingAddressFields", "checkbox"]

  connect () {
    this.toggle()
  }
  
  toggle () {
    this.billingAddressFieldsTarget.classList.toggle("hidden", this.checkboxTarget.checked)
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["pagination", "spinner"]

  connect () {
    this.scroll()
  }

  scroll () {
    if (isVisible) this.load()
  }

  load () {
    let submit = this.paginationTarget.querySelector("input[type='submit']")
    if (!!submit) submit.click()
  }
}

function isVisible (element) {
  let rect = element.getBoundingClientRect()

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

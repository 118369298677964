import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button"]

  connect() {}

  enableButton() {
    this.buttonTarget.disabled = false
  }
}

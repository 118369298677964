import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["adjustable"]
  static values = {}

  update(event) {
    const flavor = event.currentTarget.value
    const url = new URL(this.adjustableTarget.dataset.tomSelectUrlValue)

    url.searchParams.set("flavor", flavor)
    this.adjustableTarget.dataset.tomSelectUrlValue = url.toString()
    this.emitEvent()
  }

  emitEvent(){
    const event = new Event("tags--dynamic-flavor:updated")
    document.dispatchEvent(event)
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal"]

  keyboardClear (event) {
    if (event.code === "Escape") this.clear()
  }

  backgroundClear (event) {
    // let isSibling = event.target.parentNode == this.modalTarget.parentNode

    // Adding this as a hackaround to allow Trix attachments. Perhaps there's a better way.
    let isTrixAttribute = event.target instanceof HTMLInputElement && event.target.getAttribute("hidden") == "true"

    if (!this.modalTarget.contains(event.target) && !isTrixAttribute) this.clear()
  }

  clear () {
    this.element.remove()
  }
}

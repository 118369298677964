import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

const OFFSET_PIXELS = 400
export default class extends Controller {
  static targets = ["records", "pagination", "spinner"]

  scroll() {
    let nextPage = this.paginationTarget.querySelector("a[rel='next']")
    if (!nextPage) return

    const url = nextPage.href

    let body = document.body,
      html = document.documentElement

    let height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)

    if (window.pageYOffset >= height - window.innerHeight - OFFSET_PIXELS) this.loadMore(url)
  }

  async loadMore(url) {
    if (this.isLoading) return
    this.isLoading = true
    this.spinnerTarget.classList.remove("hidden")

    const response = await get(url)
    if (response.ok) {
      this.spinnerTarget.classList.add("hidden")
      this.recordsTarget.insertAdjacentHTML("beforeend", data.records)
      this.paginationTarget.innerHTML = data.pagination
      this.isLoading = false
    }
  }
}

import { Controller } from "@hotwired/stimulus"

const PAID_MEDIA_MINIMUM_AMOUNT = 50000
const PAID_MEDIA_INCREMENT_PERCENTAGE = 0.1
const PAID_MEDIA_FIRST_INCREMENT_PERCENTAGE = 0.4

export default class extends Controller {
  static targets = [
    "totalPrice",
    "product",
    "summary",
    "summaryPlaceholder",
    "summaryTotals",
    "currencySymbol",
    "formSubmitButton",
    "creatorPaidMedia",
    "summaryDraw",
    "item",
    "counter"
  ]

  connect() {
    this.currencySymbol = this.currencySymbolTarget.value
    this.summary = []
    this.setupSummary()
  }

  changeQuantity(event, paramOverride = null) {
    const params = !!paramOverride ? paramOverride : event.params
    const item = this.summary.find(item => item.title == params.title && item.kind == params.kind)

    // Changing item quantity
    console.log(event.params)
    if (params.direction === -1 && item.quantity < 1) return

    // TODO Cleanup conditional
    const isMinimumQuantity =
      !!item && !!params.minimumQuantity && (item.quantity === 0 || (item.quantity === params.minimumQuantity && params.direction === -1))
    item.quantity += isMinimumQuantity ? params.minimumQuantity * params.direction : params.direction

    // Calculating price
    if (params.kind === "product") item.price = item.quantity * item.fee

    const channelPrice = this.summary
      .filter(product => product.channel === params.channel && product.kind === "product")
      .reduce((sum, product) => sum + product.price, 0)

    // Recalculate paid media on any change
    const paidMediaItems = this.summary.filter(product => product.channel === params.channel && product.kind === "paid_media")
    paidMediaItems.forEach(item => {
      if (item.quantity > 0) {
        item.price = Math.max(channelPrice * PAID_MEDIA_FIRST_INCREMENT_PERCENTAGE, PAID_MEDIA_MINIMUM_AMOUNT)
        item.price += channelPrice * (item.quantity - 1) * PAID_MEDIA_INCREMENT_PERCENTAGE
      } else {
        item.price = 0
      }
    })

    // Setting new values
    const itemTarget = this.itemTargets.find(el => el.dataset.title == params.title && el.dataset.kind == params.kind)
    itemTarget.value = item.quantity

    const counterElement = this.counterTargets.find(el => el.dataset.title == params.title && el.dataset.kind == params.kind)
    counterElement.innerText = item.quantity

    // Setting total values
    this.totalPriceCents = this.summary.reduce((sum, item) => sum + item.price, 0)
    this.summaryTotalsTarget.classList.toggle("hidden", this.totalPriceCents < 1)

    if (this.totalPriceCents >= 0) {
      this.totalPrice = (this.totalPriceCents / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      this.totalPriceTarget.innerText = `${this.currencySymbol} ${this.totalPrice}`
    }

    // Drawing summary
    const hasProducts = this.summary.filter(item => item.kind === "product" && item.quantity > 0).length > 0
    this.formSubmitButtonTarget.classList.toggle("hidden", !hasProducts)
    // this.drawSummary()
  }

  drawSummary() {
    const channels = [
      ...new Set(
        this.summary.map(c => {
          return c.channel
        })
      )
    ] // new Set removes duplicates
    const hasProducts = this.summary.filter(item => item.kind === "product" && item.quantity > 0).length > 0

    this.summaryDrawTarget.innerHTML = "" // Remove current drawn summary contents

    channels.forEach(c => {
      const contents = this.summary.filter(i => i.channel === c)
      const hasProductsInChannel = contents.reduce((acc, p) => acc + p.quantity, 0) > 0

      const channelWrapper = document.createElement("ul")

      if (hasProductsInChannel) {
        contents.reduce((acc, p) => acc + p.quantity, 0)
        this.summaryDrawTarget.innerHTML += `<h5 class="text-sm text-gray-600 font-semibold">${c}</h5>`

        channelWrapper.classList.add("border", "rounded-lg", "divide-y")
        this.summaryDrawTarget.appendChild(channelWrapper)
      }

      contents.forEach(item => {
        if (item.quantity > 0) {
          channelWrapper.innerHTML += `
          <li class="flex justify-between text-sm py-3 px-4">
            <p><span class="text-green-500 mr-1 font-semibold">${item.quantity} ×</span>${item.title}</p>
            <div class="inline-flex text-green-800 font-semibold">${this.currencySymbol}${(item.price / 100).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}</div>
          </li>
          `
        }
      })
    })

    this.summaryPlaceholderTarget.classList.toggle("hidden", hasProducts)
    this.formSubmitButtonTarget.classList.toggle("hidden", !hasProducts)
  }

  setupSummary() {
    this.itemTargets.forEach(item => {
      const quantity = parseInt(item.value) || 0
      const fee = parseFloat(item.dataset.fee) || 0
      this.summary.push({
        kind: item.dataset.kind,
        title: item.dataset.title,
        channel: item.dataset.channel,
        fee: fee,
        quantity: quantity,
        price: quantity * fee
      })

      // Overriding the event params to set initial values.
      this.changeQuantity(
        {},
        {
          direction: 0,
          kind: item.dataset.kind,
          channel: item.dataset.channel,
          title: item.dataset.title
        }
      )
    })
    this.drawSummary()
  }

  // MAKE SURE TO MIGRATE THIS TO A DIFFERENT CONTROLLER
  submitForm() {
    document.getElementById("new_campaign_form").submit()
  }
}

import { Controller } from "@hotwired/stimulus"
import { Sortable } from "@shopify/draggable"
import { FetchRequest } from "@rails/request.js"

export default class extends Controller {
  static targets = ["sortableContainer", "sortableItem"]

  connect() {
    const that = this
    const sortableSelector = '[data-admin--sortable-creators-target="sortableItem"]'

    this.sortable = new Sortable(this.sortableContainerTarget, {
      draggable: sortableSelector,
      mirror: {
        appendTo: that.sortableContainerTarget,
        constrainDimensions: true
      }
    })

    this.sortable.on("sortable:stop", () => {
      that.updatePositions()
    })
  }

  itemClick(event) {
    if (this.sortableEnabled) event.preventDefault()
  }

  async updatePositions() {
    let payload = { creator_collection_membership_positions: this.constructUpdatableHash() }

    const request = new FetchRequest("put", `/api/v1/creator_collection_memberships/update_positions`, {
      body: JSON.stringify(payload)
    })

    const response = await request.perform()

    if (!response.ok) {
      console.log("Something went wrong!")
    }
  }

  constructUpdatableHash() {
    let hash = {}
    const elements = this.sortableItemTargets.filter(
      element => !element.classList.contains("draggable-mirror") && !element.classList.contains("draggable--original")
    )

    elements.forEach((item, position) => {
      hash[item.dataset.membershipId] = { position: position }
    })
    return hash
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["standardAgreementView", "customAgreementView", "standardAgreementButton", "customAgreementButton", "viewPlaceholder"]
  static values = { activeButtonClass: String }

  connect () {
    this.activeButtonClassList = this.activeButtonClassValue.split(" ")

    // Javascript...
    const that = this
    // this.toggleView({}, "standardAgreement")
  }

  toggleView (event, targetView = "") {
    // Determining which view is being enabled
    const enabledView = !!targetView ? targetView : event.currentTarget.dataset.view

    // this.viewPlaceholderTarget.classList.toggle("hidden", true)

    if (enabledView === "standardAgreement") {
      this.enabledViewTarget = this.standardAgreementViewTarget
      this.enabledViewButtonTarget = this.standardAgreementButtonTarget
      this.disabledViewTarget = this.customAgreementViewTarget
      this.disabledViewButtonTarget = this.customAgreementButtonTarget
    } else {
      this.enabledViewTarget = this.customAgreementViewTarget
      this.enabledViewButtonTarget = this.customAgreementButtonTarget
      this.disabledViewTarget = this.standardAgreementViewTarget
      this.disabledViewButtonTarget = this.standardAgreementButtonTarget
    }

    // Hiding/showing relevant views
    this.enabledViewTarget.classList.toggle("hidden", false)
    this.disabledViewTarget.classList.toggle("hidden", true)

    // Perhaps there's a cleaner solution for adding/removing classes
    this.activeButtonClassList.forEach(c => {
      this.enabledViewButtonTarget.classList.add(c)
      this.disabledViewButtonTarget.classList.remove(c)
    })
  }
}

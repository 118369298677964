import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

export default class extends Controller {
  static targets = ["button", "spinner"]
  static values = {
    url: String,
    campaignId: String
  }

  async syncZendesk () {
    this.spinnerTarget.classList.add("animate-spin")

    const payload = { id: this.campaignIdValue }
    const response = await post(`${this.urlValue}`, { body: JSON.stringify(payload) })
    if (response.ok) this.spinnerTarget.classList.remove("animate-spin")
  }
}

import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

export default class extends Controller {
  static targets = ["email"]
  static values = {
    organizationId: Number
  }

  async invite () {
    const email = this.emailTarget.value
    if (!!email) {
      const response = await post(`/organizations/${this.organizationIdValue}/invite`, { body: JSON.stringify({ email: email }) })
      if (response.ok) this.emailTarget.value = ""
    } else {
      return
    }
  }
}

import { Controller } from "@hotwired/stimulus"
// import autoComplete from '@tarekraafat/autocomplete.js'


export default class extends Controller {
  static targets = ['output']

  connect () {
    console.log('Hello, Stimulus!')
  }
}

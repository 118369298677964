import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["slide", "indicator", "nextButton", "prevButton"]
  static values = {
    mode: {
      type: String,
      default: "slide" // or "viewport"
    }
  }

  connect () {
    this.index = 0
    this.indicatorTargets.forEach((indicator, index) => {
      indicator.classList.toggle("bg-gray-600", index == this.index)
      indicator.classList.toggle("bg-gray-200", index != this.index)
    })
  }

  move (event) {
    this.index += event.params.vector
    this.slideTargets[this.index].scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" })

    this.nextButtonTarget.disabled = this.index >= this.slideTargets.length - 1
    this.prevButtonTarget.disabled = this.index == 0

    this.toggleIndicators()
  }

  toggleIndicators () {
    this.indicatorTargets.forEach((indicator, index) => {
      indicator.classList.toggle("bg-gray-600", index == this.index)
      indicator.classList.toggle("bg-gray-200", index != this.index)
    })
  }
}

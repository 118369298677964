import { Controller } from "@hotwired/stimulus"
import { get, post, put } from "@rails/request.js"

export default class extends Controller {
  static values = {
    clientDisplayName: {
      type: String,
      default: "No Logo X"
    },
    environment: {
      type: String,
      default: "sandbox" // "sandbox" | "staging" | "production"
    },
    userId: String,
    token: String
  }

  initialize() {
    this.config = {
      clientDisplayName: this.clientDisplayNameValue,
      environment: this.environmentValue,
      userId: this.userIdValue,
      token: this.tokenValue
    }
  }

  connect() {
    this.tokenValue ? this.initializePhyllo() : this.fetchSDKToken()
  }

  initializePhyllo() {
    if (!this.tokenValue) return

    this.phyllo = PhylloConnect.initialize(this.config)
    this.element.disabled = false

    // Registering event handlers
    this.phyllo.on("accountConnected", this.handleAccountConnected)
    this.phyllo.on("accountDisconnected", this.handleAccountDisonnected)
    this.phyllo.on("tokenExpired", this.handleTokenExpired)
    this.phyllo.on("connectionFailure", this.handleConnectionFailure)
    this.phyllo.on("exit", this.handleExit)
  }

  // Actions
  open() {
    if (!!this.phyllo) this.phyllo.open()
  }

  async fetchSDKToken() {
    const response = await get("/phyllo/sdk_token", { responseKind: "json" })
    if (response.ok) {
      const token = (await response.json)["sdk_token"]
      if (token.length) this.tokenValue = token
    }
  }

  // Callbacks
  tokenValueChanged() {
    this.config["token"] = this.tokenValue
    if (this.tokenValue) this.initializePhyllo()
  }

  // Handlers
  handleAccountConnected(phyllo_account_id, phyllo_work_platform_id, phyllo_user_id) {
    console.group("Phyllo - Account Connected")

    const payload = { phyllo_account_id, phyllo_work_platform_id, phyllo_user_id }
    post("/phyllo/connect_account", { responseKind: "json", body: JSON.stringify(payload) })

    console.groupEnd()
  }

  handleAccountDisonnected(phyllo_account_id, phyllo_work_platform_id, phyllo_user_id) {
    console.group("Phyllo - Account Disonnected")

    const payload = { phyllo_account_id, phyllo_work_platform_id, phyllo_user_id }
    put("/phyllo/disconnect_account", { responseKind: "json", body: JSON.stringify(payload) })

    console.groupEnd()
  }

  handleTokenExpired(userId) {
    console.group("Phyllo - Token Expired")
    console.log("User ID", userId)
    console.groupEnd()
  }

  handleExit(reason, userId) {
    console.group("Phyllo - Exit")
    console.log("Reason", reason)
    console.log("User ID", userId)
    console.groupEnd()
  }

  handleConnectionFailure(reason, workplatformId, userId) {
    console.group("Phyllo - Connection failure")
    console.log("Reason", reason)
    console.log("Work Platform ID", workplatformId)
    console.log("User ID", userId)
    console.groupEnd()
  }
}

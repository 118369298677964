import { Controller } from "stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  static values = {
    maxItems: { type: Number, default: 999 },
    placeholder: { type: String, default: "Select..." },
    searchField: { type: String, default: "title" },
    labelField: { type: String, default: "title" },
    valueField: { type: String, default: "id" },
    loadThrottle: { type: Number, default: 300 },
    refreshThrottle: { type: Number, default: 300 },
    url: { type: String, default: "" },
    subtitleField: String,
    customValues: String
  }

  connect () {
    this.initTomSelect()
    const that = this
    document.addEventListener("tags--dynamic-flavor:updated", () => {
      that.unmountTomSelect()
      that.initTomSelect()
    })
  }

  disconnect () {
    this.unmountTomSelect()
  }

  initTomSelect () {
    let url = this.urlValue && new URL(this.urlValue)
    const options = {
      plugins: ["remove_button"],
      maxItems: this.maxItemsValue != 0 ? this.maxItemsValue : null,
      placeholder: this.placeholderValue,
      loadThrottle: this.loadThrottleValue,
      refreshThrottle: this.refreshThrottleValue,
      hidePlaceholder: true,
      shouldLoad: (query) => { return url },
      load: (query, callback) => {
        if(url) { // ignore if no url
          url.searchParams.set("query", encodeURIComponent(query))
          fetch(url.href)
            .then(response => response.json())
            .then(json => {
              callback(json)
            })
            .catch(() => {
              callback()
            })
        }
      }
    }

    options["valueField"] = this.valueFieldValue
    options["labelField"] = this.labelFieldValue
    options["searchField"] = this.searchFieldValue

    if (this.hasSubtitleFieldValue) {
      options["render"] = {
        option: (data, escape) => {
          let label = data[this.labelFieldValue]
          let subtitle

          if (!!data[this.subtitleFieldValue]) subtitle = `${data[this.subtitleFieldValue]} - `
          let text = `${subtitle}${label}`

          return "<div>" + '<span class="block">' + escape(text) + "</span>"
        }
      }
    }

    this.select = new TomSelect(this.element, options)

    // remove the input text after adding an item
    this.input = this.element.querySelector('input[type="select-multiple"]')
    this.select.on('item_add', function(value, item) {
      this.input.nextSibling.querySelector("input").value = ''
    });

  }

  unmountTomSelect(){
    if (this.select) this.select.destroy()
  }

}

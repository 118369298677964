import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggleableColumn", "gridWrapper", "inactiveButton", "activeButton"]

  toggle () {
    // Column
    this.toggleableColumnTarget.classList.toggle("hidden")

    // Grid
    this.gridWrapperTarget.classList.toggle("md:grid-cols-4")
    this.gridWrapperTarget.classList.toggle("md:grid-cols-3")

    // Button
    this.inactiveButtonTarget.classList.toggle("hidden")
    this.activeButtonTarget.classList.toggle("hidden")
  }
}

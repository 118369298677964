import { Controller } from "@hotwired/stimulus"
import { Sortable } from "@shopify/draggable"

// import Rails from "@rails/ujs"
import { FetchRequest } from "@rails/request.js"

export default class extends Controller {
  static targets = ["sortableContainer", "sortableItem", "enableSortableButton", "disableSortableButton"]
  static values = {
    campaignId: String,
    uploadsStatus: String
  }

  connect () {
    this.sortableEnabled = false
  }

  enableSortable () {
    this.sortableEnabled = true

    this.enableSortableButtonTarget.classList.add("hidden")
    this.disableSortableButtonTarget.classList.remove("hidden")
    this.sortableContainerTarget.classList.add("p-2", "border-2", "border-black", "border-dashed", "bg-gray-100")

    this.sortableItemTargets.forEach(item => {
      item.classList.add("cursor-move")
    })

    const container = this.sortableContainerTarget
    const sortableSelector = '[data-campaigns--sortable-uploads-target="sortableItem"]'

    this.sortable = new Sortable(container, {
      draggable: sortableSelector,
      mirror: {
        appendTo: container,
        constrainDimensions: true
      }
    })

    let that = this
    this.sortable.on("sortable:stop", () => {
      that.updatePositions()
    })
  }

  disableSortable () {
    this.sortableEnabled = false

    this.disableSortableButtonTarget.classList.add("hidden")
    this.enableSortableButtonTarget.classList.remove("hidden")
    this.sortableContainerTarget.classList.remove("p-4", "border-2", "border-dashed", "bg-gray-100")

    this.sortableItemTargets.forEach(item => {
      item.classList.remove("cursor-move")
    })

    this.sortable.destroy()
  }

  itemClick (event) {
    if (this.sortableEnabled) event.preventDefault()
  }

  async updatePositions () {
    let payload = {
      upload_positions: this.constructUpdatableHash()
    }

    const request = new FetchRequest("post", `/api/v1/campaigns/${this.campaignIdValue}/uploads/update_positions`, {
      body: JSON.stringify(payload)
    })
    const response = await request.perform()

    if (!response.ok) {
      console.log("Something went wrong!")
    }
  }

  // Private
  constructUpdatableHash () {
    let hash = {}
    let position

    // Taking away Sortable's temporary elements
    const elements = this.sortableItemTargets.filter(
      element => !element.classList.contains("draggable-mirror") && !element.classList.contains("draggable--original")
    )

    elements.forEach((item, index) => {
      position = index + 1

      // Ensuring that positions don't collide when changing upload status
      switch (this.uploadsStatusValue) {
        case "pending":
          position *= 100
          break
        case "declined":
          position *= 10000
        default:
          break
      }

      hash[item.dataset.uploadId] = { position: position }
    })
    return hash
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["spinner"]

  invoke () {
    this.element.disabled = true
    this.spinnerTarget.classList.toggle("hidden")
  }
}

import { Controller } from "@hotwired/stimulus"

const spinner = `<svg class="animate-spin inline-flex h-4 w-4 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
</svg>`

export default class extends Controller {
  static targets = ["spinner"]
  static values = { spinnerClassList: String }

  connect () {
    this.spinner = !this.hasSpinnerTarget ? spinner : this.spinnerTarget
  }

  submit (event) {
    // if (this.hasSpinnerTarget) {
    //   this.spinnerTarget.classList.remove("hidden")
    //   // setTimeout(()=>{this.spinnerTarget.classList.toggle("hidden")}, 2000)
    // }
    event.currentTarget.innerHTML = this.spinner + event.currentTarget.innerHTML
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "agencyCheckbox", "additionalFields" ]

  connect() {
    this.toggleAgency()
  }

  toggleAgency (event) {
    const checkbox = this.agencyCheckboxTarget
    this.additionalFieldsTarget.classList.toggle('hidden', !checkbox.checked)
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["links", "template"]

  connect() {}

  addAssociation(event) {
    event.preventDefault();

    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime());
    document.getElementById('promotionExamplesWrapper').insertAdjacentHTML('beforeend', content)

    // this.linksTarget.insertAdjacentHTML('beforebegin', content);
  }

  removeAssociation(event) {
    event.preventDefault();

    let wrapper = event.target.closest('.nested-promotion-examples-fields');
    if (wrapper === "true") {
      wrapper.remove();
    } else {
      wrapper.querySelector("input[name*='_destroy'").value = 1;
      wrapper.style.display = "none";
    }
  }
}
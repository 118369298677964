import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["kindSelect", "scriptField", "captionField", "contentColumn", "uploadColumn"]
  static values = {
    collapsed: Boolean
  }
  connect () {
    this.toggle()
  }

  toggle (event) {
    const hideScript = this.kindSelectTarget.value !== "script"
    this.scriptFieldTarget.classList.toggle("hidden", hideScript)
    this.captionFieldTarget.classList.toggle("hidden", !hideScript)
    this.uploadColumnTarget.classList.toggle("hidden", !hideScript)
  }
}

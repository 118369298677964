import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "submit", "modal"]
  static values = {
    url: String
  }

  connect () {}

  open () {
    if (this.checkboxTarget.checked) document.getElementById("sign_up_legals").setAttribute("src", this.urlValue)
  }

  accept () {
    this.modalTarget.innerHTML = ""
    this.checkboxTarget.checked = true
    this.submitTarget.removeAttribute("disabled")

  }
  
  decline () {
    this.modalTarget.innerHTML = ""
    this.checkboxTarget.checked = false
    this.submitTarget.setAttribute("disabled", "")
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["output"]
  static values = {
    url: String,
    providerName: String
  }

  connect () {
    fetch(this.urlValue)
      .then((response) => response.json())
      .then((data) => console.log(data))
  }
}

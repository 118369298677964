import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['mobileMenu', 'hamburgerIcon', 'closeIcon']

  connect () {
    this.displayMobileMenu = false
  }

  toggleMobileMenu () {
    this.displayMobileMenu = !this.displayMobileMenu

    this.hamburgerIconTarget.classList.toggle('hidden', this.displayMobileMenu)
    this.closeIconTarget.classList.toggle('hidden', !this.displayMobileMenu)

    this.mobileMenuTarget.classList.toggle('hidden', !this.displayMobileMenu)
  }
}

import { Controller } from "@hotwired/stimulus"
import { useDebounce } from "stimulus-use"

const upKey = 38
const downKey = 40
const enterKey = 13
const navigationKeys = [upKey, downKey, enterKey]

export default class extends Controller {
  static targets = ["input", "results", "result"]
  static debounces = ["submit"]
  static classes = ["activeResult"]

  connect () {
    this.resultIndex = null
    useDebounce(this, { wait: 300 })
  }

  submit (event) {
    event.target.form.requestSubmit()
  }

  focus (event) {
    // Not using this.submit because it's debounced
    if (this.resultsTarget.childNodes.length === 0) event.target.form.requestSubmit()
  }

  handleKeydown (event) {
    if (navigationKeys.includes(event.keyCode) && this.resultTargets.length > 0) {
      if (event.keyCode === upKey || event.keyCode === downKey) {
        const delta = event.keyCode === upKey ? -1 : 1
        if (this.resultIndex === null) this.resultIndex = delta == -1 ? 0 : -1
        this.resultIndex = (this.resultIndex + this.resultTargets.length + delta) % this.resultTargets.length
        this.resultTargets.forEach((el, index) => el.classList.toggle(this.activeResultClass, index === this.resultIndex))
      } else {
        this.resultTargets[this.resultIndex].click()
      }
    }
  }

  hideOnOffclick (event) {
    if (
      this.inputTarget.contains(event.target) ||
      this.resultsTarget.contains(event.target) ||
      event.target.getAttribute("type") === "submit"
    )
      return
    this.reset()
  }

  // Private methods

  reset () {
    this.resultsTarget.innerHTML = ""
    this.inputTarget.value = ""
  }
}

import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

export default class extends Controller {
  static targets = ["actionButton", "disabledButton"]
  static values = {
    postId: String
  }

  async commit (event) {
    const response = await post(`/posts/${this.postIdValue}/interested`)

    if (response.ok) {
      this.actionButtonTarget.classList.add("hidden")
      this.disabledButtonTarget.classList.remove("hidden")
    }
  }
}

import { Controller } from "@hotwired/stimulus"
import { useDebounce } from "stimulus-use"
export default class extends Controller {
  static debounces = ["submit"]
  static values = {
    interval: { type: Number, default: 300 },
  }

  connect () {
    useDebounce(this, { wait: this.intervalValue })
  }

  submit (event) {
    event.target.form.requestSubmit()
  }
}

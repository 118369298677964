import { Controller } from "@hotwired/stimulus"
// import { enter, leave } from "el-transition"
// import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["expandable"]

  connect() {
    this.expandedFilters = JSON.parse(localStorage.getItem("nologox_search_expanded_filters")) || []
    this.expandFilters()
  }

  submit(event) {
    const form = event.target.form
    form.requestSubmit()
  }

  persistFilterExpansion(event) {
    let filterId = event.target.id
    if (event.target.open) {
      if (!this.expandedFilters.includes(filterId)) this.expandedFilters.push(filterId)
    } else {
      this.expandedFilters = this.expandedFilters.filter(filter => filter !== filterId)
    }
    localStorage.setItem("nologox_search_expanded_filters", JSON.stringify(this.expandedFilters))
  }

  expandFilters() {
    this.expandedFilters.forEach(filter => {
      if (document.getElementById(filter)) document.getElementById(filter).open = true
    })
  }
}

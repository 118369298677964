import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "attribute" ]

  setDestroy() {
    this.element.classList.add("hidden")
    this.attributeTarget.value = true
  }
}

import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
import { useDebounce } from "stimulus-use"

export default class extends Controller {
  static targets = ["input", "dropdown", "suggestionLink"]
  static values = { suggestionEndpointUrl: String }
  static debounces = ["handleInput"]

  connect() {
    useDebounce(this, { wait: 300 })

    // this.toggleDropdown()
    // this.handleInput()
  }

  toggleDropdown(event) {
    if (!this.suggestionLinkTargets.includes(event.relatedTarget)) {
      this.dropdownTarget.classList.toggle("hidden", event?.type != "focus")
    }
  }

  handleInput() {
    get(this.suggestionEndpointUrlValue, { query: { query: this.inputTarget.value }, responseKind: "turbo-stream" })

    // this.suggestionLinkTargets.forEach(anchor => {
    //   let url = new URL(anchor.href)
    //   url.searchParams.set("query", this.inputTarget.value)
    //   anchor.href = url.toString()
    // })
  }
}

import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
import { useDebounce } from "stimulus-use"

export default class extends Controller {
  static targets = ["location", "locationInput", "longitude", "latitude", "results"]
  static debounces = ["geolocate"]

  connect() {
    useDebounce(this, { wait: 400 })
  }

  submit () {
    this.element.requestSubmit()
  }

  selectLocationResult (event) {
    this.longitudeTarget.value = event.target.dataset.longitude
    this.latitudeTarget.value = event.target.dataset.latitude
    document.getElementById("location_query").value = event.target.dataset.query
    this.submit()
  }

  async geolocate (event) {
    if (!!this.locationInputTarget.value) {
      const response = await get("/creators/creator_locations", { query: { query: this.locationInputTarget.value }, responseKind: "turbo-stream" })
      if (response.ok) {
        event.target.value = this.locationInputTarget.value
      }
    } else {
      this.resultsTarget.innerHTML = ""
    }
  }
}

// Legacy code just in case.

// const url = constructURL(event)
// get(url.href, {
//   responseKind: "turbo-stream"
// })

// function constructURL (event) {
//   const url = new URL(window.location.href)
//   const params = new URLSearchParams(url.search)

//   if (!!event.target.selectedOptions[0].value) {
//     params.has(event.params.paramName)
//       ? params.set(event.params.paramName, event.target.selectedOptions[0].value)
//       : params.append(event.params.paramName, event.target.selectedOptions[0].value)
//   } else {
//     params.delete(event.params.paramName)
//   }
//   url.search = params.toString()
//   return url
// }

import { Controller } from "@hotwired/stimulus"
import { ApplicationController, useDebounce } from "stimulus-use"

const upKey = 38
const downKey = 40
const enterKey = 13
const navigationKeys = [upKey, downKey, enterKey]

export default class extends Controller {
  static targets = ["query", "results", "location", "longitude", "latitude"]
  static values = {
    url: String,
    locationUrl: String
  }

  static debounces = ["fetchResults", "fetchLocation"]

  connect () {
    useDebounce(this)
  }

  disconnect () {
    this.reset()
  }
  fetchResults (event) {
    if (navigationKeys.includes(event.keyCode)) return

    if (this.query === "") {
      this.reset()
      return
    }

    if (this.query == this.previousQuery) return

    this.previousQuery = this.query

    const url = new URL(this.urlValue)
    url.searchParams.append("query", this.query)
    if (!!this.location && this.longitudeTarget.value && this.latitudeTarget.value) {
      url.searchParams.append("location_q", this.location)
      url.searchParams.append("longitude", this.longitudeTarget.value)
      url.searchParams.append("latitude", this.latitudeTarget.value)
    }

    this.abortPreviousFetchRequest()

    this.abortController = new AbortController()
    fetch(url, { signal: this.abortController.signal })
      .then(response => response.text())
      .then(html => {
        this.resultsTarget.innerHTML = html
      })
      .catch(() => {})
  }

  navigateResults (event) {
    if (event.keyCode === enterKey) {
      let redirectUrl = `/creators/?`
      if (this.query) redirectUrl += `&features_q=${this.query}`
      if (this.location) {
        redirectUrl += `&location_q=${this.query}`
        redirectUrl += `&latitude=${this.latitudeTarget.value}`
        redirectUrl += `&longitude=${this.longitudeTarget.value}`
      }
      window.location.href = redirectUrl
    } else {
      if (this.searchResultsController) this.searchResultsController.navigateResults(event)
    }
  }

  fetchLocation (event) {
    if (navigationKeys.includes(event.keyCode)) return

    if (this.location === "") {
      this.reset()
      return
    }

    if (this.location == this.previousQuery) return

    this.previousQuery = this.location

    const url = new URL(this.locationUrlValue)
    url.searchParams.append("query", this.location)

    this.abortPreviousFetchRequest()

    this.abortController = new AbortController()
    fetch(url, { signal: this.abortController.signal })
      .then(response => response.text())
      .then(html => {
        this.resultsTarget.innerHTML = html
      })
      .catch(() => {})
  }

  navigateLocation (event) {
    if (event.keyCode === enterKey) {
      let redirectUrl = `/creators/?`
      if (this.query) redirectUrl += `&features_q=${this.query}`
      if (this.location) {
        redirectUrl += `&location_q=${this.query}`
        redirectUrl += `&latitude=${this.latitudeTarget.value}`
        redirectUrl += `&longitude=${this.longitudeTarget.value}`
      }
      window.location.href = redirectUrl
    } else {
      if (this.searchResultsController) this.searchResultsController.navigateResults(event)
    }
  }

  hideOnOffclick (event) {
    if (this.resultsTarget.contains(event.target) || this.queryTarget.contains(event.target)) {
      return
    } else {
      this.reset()
    }
  }

  setCoordinates ({ detail: { coordinates, text } }) {
    this.latitudeTarget.value = coordinates.latitude
    this.longitudeTarget.value = coordinates.longitude
    this.locationTarget.value = text
    this.reset()
  }

  // private

  reset () {
    this.resultsTarget.innerHTML = ""
    this.queryTarget.value = ""
    this.previousQuery = null
  }

  abortPreviousFetchRequest () {
    if (this.abortController) {
      this.abortController.abort()
    }
  }

  get query () {
    return this.queryTarget.value
  }

  get location () {
    return this.locationTarget.value
  }

  get searchResultsController () {
    return this.application.getControllerForElementAndIdentifier(this.resultsTarget.firstElementChild, "shared--search-results")
  }
}

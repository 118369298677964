import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "buttonContainer"]

  expand (event) {
    this.contentTarget.classList.remove("max-h-64")
    this.contentTarget.classList.remove("overflow-y-hidden")
    this.buttonContainerTarget.classList.add("hidden")
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["slide"]
  static values = { index: Number }

  next () {
    this.indexValue++
    if (this.indexValue === this.slideTargets.length) {
      this.indexValue -= this.slideTargets.length
    }
  }

  previous () {
    this.indexValue--
    if (this.indexValue < 0) {
      this.indexValue += this.slideTargets.length
    }
  }

  indexValueChanged () {
    this.showCurrentSlide()
  }

  showCurrentSlide () {
    this.slideTargets.forEach((element, index) => {
      element.classList.toggle("hidden", index != this.indexValue)
    })
  }
}

import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"

export default class extends Controller {
  static targets = ["button", "spinner"]
  static values = {
    url: String,
    campaignId: String,
    payload: String,
    method: String
  }

  async execute () {
    this.spinnerTarget.classList.add("animate-spin")

    const payload = JSON.parse(this.payloadValue)
    const request = new FetchRequest(this.methodValue, this.urlValue, { body: JSON.stringify(payload) })
    const response = await request.perform()
    if (response.ok) {
      this.spinnerTarget.classList.remove("animate-spin")
    }
  }
}

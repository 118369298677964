import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "acceptCheckbox", "additionalFields" ]

  connect() {
    this.toggleAcceptance()
  }

  toggleAcceptance (event) {
    const checkbox = this.acceptCheckboxTarget

    if (checkbox.checked) {
      this.additionalFieldsTarget.classList.add('hidden')
    } else {
      this.additionalFieldsTarget.classList.remove('hidden')
    }
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "totalPrice",
    "totalDiscount",
    "product",
    "summary",
    "summaryPlaceholder",
    "summaryTotals",
    "totalDiscountWrapper",
    "currencySymbol",
    "formSubmitButton",
    "creatorPaidMedia",
    "brandPaidMedia",
    "summaryDraw"
  ]

  connect () {
    // Calculator parameters
    this.maximumDiscount = 0.15
    this.maximumDiscountThreshold = 500000 // IN CENTS
    this.minimumDiscount = 0.1
    this.minimumDiscountThreshold = 200000 // IN CENTS

    this.creatorPaidMediaMultiplier = 0.35
    this.brandPaidMediaMultiplier = 0.35

    this.currencySymbol = this.currencySymbolTarget.value
    this.totalPrice
    this.totalPriceCents = 0
    this.totalDiscount
    this.totalDiscountCents = 0
    this.totalPaidMedia
    this.totalPaidMediaCents = 0
    this.totalPriceTarget.textContent = `€${this.totalPriceCents}`

    this.initializeSummary()
  }

  initializeSummary () {
    this.summary = []
    this.paidMediaSummary = []
    this.productTargets.forEach(p => {
      const quantity = parseInt(p.value) || 0
      const price = quantity * parseFloat(p.dataset.fee)

      this.summary.push({
        kind: "product",
        title: p.dataset.title,
        channel: p.dataset.channel,
        fee: parseFloat(p.dataset.fee),
        averageImpressions: parseInt(p.dataset.averageImpressions) || 0,
        quantity: quantity,
        price: price
      })
    })

    this.creatorPaidMediaTargets.forEach(t => {
      const quantity = parseInt(t.value) || 0

      this.summary.push({
        kind: "paid_media",
        paidMediaChannel: "creator",
        title: t.dataset.title,
        channel: t.dataset.channel,
        quantity: quantity,
        price: 0
      })
    })

    this.brandPaidMediaTargets.forEach(t => {
      const quantity = parseInt(t.value) || 0

      this.summary.push({
        kind: "paid_media",
        paidMediaChannel: "brand",
        title: t.dataset.title,
        channel: t.dataset.channel,
        quantity: quantity,
        price: 0
      })
    })

    this.handleQuantityChange()
  }

  handleQuantityChange () {
    let productsAdded = 0

    this.productTargets.forEach(product => {
      let summaryProduct = this.summary.find(item => item.title === product.dataset.title)
      let productValue = product.value === "" ? 0 : parseFloat(product.value)

      if (productValue >= 0) {
        summaryProduct.quantity = productValue
        summaryProduct.price = summaryProduct.quantity * product.dataset.fee
      }
    })

    this.summaryTarget.textContent = ""

    this.summary.forEach(item => {
      if (item.quantity > 0 && item.kind === "product" && false) {
        productsAdded += 1
        this.summaryTarget.innerHTML += `
        <li class="flex justify-between py-3 text-sm px-4 items-center">
          <div class="inline-flex flex-grow-0 flex-shrink-0"><span class="text-green-500 mr-1 font-semibold">${item.quantity} ×</span>${
          item.title
        }</div>
          <div class="inline-flex text-green-800 font-semibold">${this.currencySymbol}${(item.price / 100).toLocaleString()}</div>
        </li>
        `
      }
    })
    let hasProductsAdded = productsAdded <= 0

    this.summaryTarget.classList.toggle("hidden", hasProductsAdded)
    this.formSubmitButtonTarget.classList.remove("hidden", hasProductsAdded)
    this.summaryPlaceholderTarget.classList.toggle("hidden", !hasProductsAdded)

    this.calculate()
  }

  handleCreatorPaidMediaQuantityChange (event) {
    const target = event.target
    const item = this.summary.find(
      item => item.kind === "paid_media" && item.paidMediaChannel === "creator" && item.channel === target.dataset.channel
    )
    const quantity = parseInt(target.value) || 0
    quantity <= parseInt(target.max) ? (item.quantity = quantity || 0) : (item.quantity = parseInt(target.max))
    this.calculate()
  }

  handleBrandPaidMediaQuantityChange (event) {
    const target = event.target
    const item = this.summary.find(
      item => item.kind === "paid_media" && item.paidMediaChannel === "brand" && item.channel === target.dataset.channel
    )
    const quantity = parseInt(target.value) || 0
    quantity <= parseInt(target.max) ? (item.quantity = quantity || 0) : (item.quantity = parseInt(target.max))
    this.calculate()
  }

  handleBrandPaidMedia (event) {
    const target = event.target
    const item = this.summary.find(
      item => item.kind === "paid_media" && item.paidMediaChannel === "brand" && item.channel === target.dataset.channel
    )
    item.quantity = parseInt(target.value) || 0
    this.calculate()
  }

  calculate () {
    const channels = [
      ...new Set(
        this.summary.map(c => {
          return c.channel
        })
      )
    ] // new Set removes duplicates

    this.totalPriceCents = 0
    this.totalDiscountCents = 0

    // Calculating paid media
    this.totalPaidMediaCents = 0
    this.summary
      .filter(p => p.kind == "paid_media")
      .forEach(pm => {
        const products = this.summary.filter(p => p.channel === pm.channel && p.kind === "product")
        const channelPrice = products.reduce((acc, p) => acc + p.price, 0)
        const multiplier = pm.paidMediaChannel === "creator" ? this.creatorPaidMediaMultiplier : this.brandPaidMediaMultiplier
        
        pm.price = Math.ceil(pm.quantity * multiplier * channelPrice)
        this.totalPaidMediaCents += pm.price
      })

    // Calculating total price estimate
    this.summary.forEach(item => {
      this.totalPriceCents += item.price
    })

    // Calculating discount per channel
    channels.forEach(channel => {
      const products = this.summary.filter(p => p.channel === channel)
      const channelProductsPrice = products.map(p => p.price)
      const channelTotalPrice = channelProductsPrice.reduce((sum, price) => {
        return sum + price
      })

      if (channelTotalPrice >= this.maximumDiscountThreshold) {
        this.totalDiscountCents += channelTotalPrice * this.maximumDiscount
      } else if (channelTotalPrice >= this.minimumDiscountThreshold) {
        this.totalDiscountCents += channelTotalPrice * this.minimumDiscount
      }
    })

    this.totalPrice = (this.totalPriceCents / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    this.totalDiscount = (this.totalDiscountCents / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    this.totalDiscountedPrice = ((this.totalPriceCents - this.totalDiscountCents) / 100).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })

    this.totalPriceTarget.textContent = `${this.currencySymbol}${this.totalDiscountedPrice}`
    this.totalDiscountTarget.textContent = `${this.currencySymbol}${this.totalDiscount}`

    // Toggling visibility classes
    if (this.totalPriceCents > 0) {
      this.summaryTotalsTarget.classList.remove("hidden")
    } else {
      this.summaryTotalsTarget.classList.add("hidden")
    }

    if (this.totalDiscountCents > 0) {
      this.totalDiscountWrapperTarget.classList.remove("hidden")
    } else {
      this.totalDiscountWrapperTarget.classList.add("hidden")
    }
    this.drawSummary()
  }

  drawSummary () {
    const channels = [
      ...new Set(
        this.summary.map(c => {
          return c.channel
        })
      )
    ] // new Set removes duplicates
    const hasProducts = this.summary.filter(i => i.kind === "product" && i.quantity > 0).length > 0

    this.summaryDrawTarget.innerHTML = "" // Remove current drawn summary contents

    channels.forEach(c => {
      const contents = this.summary.filter(i => i.channel === c)
      const hasProductsInChannel = contents.reduce((acc, p) => acc + p.quantity, 0) > 0

      const channelWrapper = document.createElement("ul")

      if (hasProductsInChannel) {
        contents.reduce((acc, p) => acc + p.quantity, 0)
        this.summaryDrawTarget.innerHTML += `<h5 class="text-lg text-green-800 font-semibold mb-2">${c}</h5>`

        channelWrapper.classList.add("border", "rounded-lg", "divide-y")
        this.summaryDrawTarget.appendChild(channelWrapper)
      }

      contents.forEach(item => {
        if (item.quantity > 0) {
          channelWrapper.innerHTML += `
          <li class="flex justify-between text-sm py-3 px-4">
            <p><span class="text-green-500 mr-1 font-semibold">${item.quantity} ×</span>${item.title}</p>
            <div class="inline-flex text-green-800 font-semibold">${this.currencySymbol}${(item.price / 100).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}</div>
          </li>
          `
        }
      })

      if (contents.reduce((acc, p) => acc + p.quantity, 0) > 0) {
        const contents = this.summary.filter(i => i.channel === c && i.quantity > 0)
        const organicImpressions = contents.reduce((acc, p) => {
          let x = p.kind === "product" ? p.averageImpressions * p.quantity : 0
          return acc + x
        }, 0)

        const paidMedia = contents.filter(p => p.kind == "paid_media" && p.quantity > 0)
        const creatorsPaidMedia = contents.find(p => p.kind == "paid_media" && p.quantity > 0 && p.paidMediaChannel === "creator")

        this.summaryDrawTarget.innerHTML += `
          <div class="px-4 flex text-sm justify-between ${paidMedia.length > 0 && creatorsPaidMedia !== undefined ? "line-through" : ""}">
            <div class="inline-flex">Organic impressions</div>
            <div class="inline-flex font-semibold flex-shrink-0">${organicImpressions.toLocaleString()}</div>
          </div>
          `

        if (paidMedia.length > 0 && creatorsPaidMedia !== undefined) {
          this.summaryDrawTarget.innerHTML += `
          <div class="px-4 flex text-sm justify-between text-green-500">
            <div class="inline-flex text-green-800">Guaranteed impressions</div>
            <div class="inline-flex font-semibold flex-shrink-0">${(
              organicImpressions *
              (1 + creatorsPaidMedia.quantity)
            ).toLocaleString()}</div>
          </div>
          `
        }
      }
    })

    this.summaryPlaceholderTarget.classList.toggle("hidden", hasProducts)
    this.formSubmitButtonTarget.classList.toggle("hidden", !hasProducts)
  }

  // MAKE SURE TO MIGRATE THIS TO A DIFFERENT CONTROLLER
  submitForm () {
    document.getElementById("new_campaign_form").submit()
  }
}

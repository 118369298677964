import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["submitButton"]

  // handleKeyUp (event) {
  //   if (event.keyCode === 13) this.submitButtonTarget.click()
  // }

  handleFormSubmit () {
    this.dispatch("messageSent")
    this.element.reset()
  }
}

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  toggle(event) {
    const tags = document.querySelectorAll(`[data-parent-tag-name="${event.params.parentName}"]`)
    tags.forEach(tag => {
      tag.classList.toggle("hidden", !event.target.checked)
    });
  }
}

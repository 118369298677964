import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { targetId: String, offsetTop: Number }

  scroll () {
    const targetElement = document.getElementById(this.targetIdValue)
    const offsetTargetYPosition = targetElement.getBoundingClientRect().top - this.offsetTopValue

    window.scrollTo({
      top: offsetTargetYPosition,
      behavior: "smooth"
    })
  }
}

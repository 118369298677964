import { Controller } from "@hotwired/stimulus"
import Calendar from "tui-calendar"

export default class extends Controller {
  static targets = ["calendar"]

  connect () {
    this.calendar = new Calendar(this.calendarTarget, {
      id: "1",
      name: "Campaigns",
      defaultView: "month",
      color: "#fffff",
      bgColor: "#fffff",
      borderColor: "green",
      // milestone: false,
      // scheduleView: true,
      useDetailPopup: true,
      isReadOnly: true,
      template: {
        popupStateFree: function () {
          return "Free"
        },
        milestone: function (schedule) {
          return '<span style="color:red;"><i class="fa fa-flag"></i> ' + schedule.title + "</span>"
        },
        milestoneTitle: function () {
          return "Milestone"
        },
        task: function (schedule) {
          return "&nbsp;&nbsp;#" + schedule.title
        },
        taskTitle: function () {
          return '<label><input type="checkbox" />Task</label>'
        },
        allday: function (schedule) {
          // return schedule.title + ' <i class="fa fa-refresh"></i>'
          return schedule.title
        },
        alldayTitle: function () {
          return "All Day"
        },
        time: function (schedule) {
          return schedule.title + ' <i class="fa fa-refresh"></i>' + schedule.start
        }
      },
      month: {
        daynames: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        startDayOfWeek: 1
      },
      week: {
        daynames: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        startDayOfWeek: 1
      }
      // theme: customTheme
    })

    
    this.renderCampaignSchedules()
    this.today()

    let event = new CustomEvent("mounted-tui-calendar", {
      "stimulus-controller": "campaigns--calendar"
    })
    window.dispatchEvent(event)
  }

  renderCampaignSchedules () {
    const campaignDates = JSON.parse(this.calendarTarget.dataset.dates)
    const deliverables = JSON.parse(this.calendarTarget.dataset.deliverables)
    let schedules = []
    let di = 0

    campaignDates.forEach( campaign => {
      schedules.push({
        calendarId: "1",
        isAllDay: true,
        category: "allday",
        color: "#ffffff",
        bgColor: "#2f9f69",
        id: campaign.id,
        title: campaign.title,
        body: deliverables[di],
        start: campaign.posting_date
      },
      {
        calendarId: "1",
        isAllDay: true,
        category: "allday",
        color: "#ffffff",
        bgColor: "#e8521a",
        id: campaign.id,
        title: campaign.title,
        body: deliverables[di],
        start: campaign.review_date
      },
      {
        calendarId: "1",
        isAllDay: true,
        category: "allday",
        color: "#ffffff",
        bgColor: "#0096ff",
        id: campaign.id,
        title: campaign.title,
        body: deliverables[di],
        start: campaign.results_date
      })
      di += 1
    })
    this.calendar.createSchedules(schedules)
  }

  next () {
    this.calendar.next()
  }

  previous () {
    this.calendar.prev()
  }

  today () {
    this.calendar.today()
    // let endMonth = this.calendar.getDateRangeEnd()._date.getMonth()
    // let startMonth = this.calendar.getDateRangeStart()._date.getMonth()

    // console.log(this.calendar.getDateRangeEnd()._date)
    // console.log(this.calendar.getDateRangeEnd()._date.getMonth())
  }
}

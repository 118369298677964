import { Controller } from "@hotwired/stimulus"
import Chart from "chart.js/auto"

export default class extends Controller {
  static targets = ["output"]
  static values = {
    kind: String,
    data: Object
  }

  connect () {
    this.canvasElement = this.element.getContext("2d")
    this.canvasElement.canvas.width = 300

    if (this.kindValue === "pie") {
      // this.canvasElement.canvas.height = 140
      this.drawPie()
    } else {
      // this.canvasElement.canvas.height = 140
      this.drawHorizontalBar()
    }
  }

  drawPie () {
    let male_percentage = this.dataValue.male_percentage

    new Chart(this.canvasElement, {
      type: this.kindValue,
      options: {
        plugins: {
          legend: {
            display: false,
            position: "bottom",
            align: "center",
            labels: {
              padding: 16,
              boxWidth: 16,
              fontSize: 16,
              fontColor: "#363636"
            }
          }
        }
      },
      data: {
        labels: ["Female", "Male"],
        datasets: [
          {
            data: [100 - male_percentage, male_percentage],
            backgroundColor: ["rgba(0, 0, 0, 1)", "rgba(255, 255, 255, 1)"]
          }
        ]
      }
    })
  }

  drawHorizontalBar () {
    let age_distribution = this.dataValue.age_distribution
    new Chart(this.canvasElement, {
      type: this.kindValue,
      responsive: true,
      options: {
        indexAxis: "y",
        plugins: { legend: { display: false } },
        scales: {
          x: { display: false },
          y: {
            grid: {
              display: false
            }
          }
        }
      },
      data: {
        labels: ["13-17", "18-24", "25-34", "35-44", "45-54", "55-64", "65+"],
        datasets: [
          {
            data: age_distribution.split(",").map(Number),
            backgroundColor: [
              "rgba(0, 0, 0, 1)",
              "rgba(0, 0, 0, 1)",
              "rgba(0, 0, 0, 1)",
              "rgba(0, 0, 0, 1)",
              "rgba(0, 0, 0, 1)",
              "rgba(0, 0, 0, 1)",
              "rgba(0, 0, 0, 1)"
            ],
            borderRadius: 9999
          }
        ]
      }
    })
  }
}

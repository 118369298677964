import { Controller } from "@hotwired/stimulus"
import consumer from "../../channels/consumer"

export default class extends Controller {
  static values = { chatId: Number, userId: Number }

  connect () {
    this.channel = consumer.subscriptions.create(
      { channel: "OnlineChannel", chat_id: this.chatIdValue, user_id: this.userIdValue },
      {
        connected: this.channelConnected.bind(this),
        disconnected: this.channelDisconnected.bind(this),
        received: this.channelReceived.bind(this)
      }
    )
  }

  channelConnected () {}
  channelDisconnected () {}
  channelReceived (data) {}
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggleIcon", "container"]
  static values = {
    collapsed: Boolean
  }

  connect () {
    if (!this.collapsedValue) this.toggle()
  }

  toggle () {
    this.toggleIconTarget.classList.toggle("rotate-180")
    this.containerTarget.classList.toggle("hidden")
  }
}
